function initMap() {
  var lat = $( ".nearest_title" ).data("coord-lat");
  var lng = $( ".nearest_title" ).data("coord-lon");
  var $cube = $( ".gha-cube");
  var $wlhp = $( ".gha-wlhp");
  var $dunedin = $( ".dunedin");
  var $loretto = $( ".loretto");
  var $barony = $( ".barony");
  var $lowther = $( ".lowther");
  var $wheatley = $( ".wheatley");
  var icon = {
    // url: '/styles/mysource_files/map-marker-gha.svg',
    url: '/__data/assets/file/0024/28356/map-marker-gha.svg',
    scaledSize: new google.maps.Size(42, 42),
  }
  if ($cube.length) {
    icon = {
      // url: '/styles/mysource_files/map-marker-cube.svg',
      url: '/__data/assets/file/0025/28357/map-marker-dunedin.svg',
      scaledSize: new google.maps.Size(42, 42),
    }
  }
  if ($wlhp.length) {
    icon = {
      // url: '/styles/mysource_files/map-marker-wlhp.svg',
      url: '/__data/assets/file/0019/28360/map-marker-wlhp.svg',
      scaledSize: new google.maps.Size(42, 42),
    }
  }
  if ($dunedin.length) {
    icon = {
      // url: '/styles/mysource_files/map-marker-dunedin.svg',
      url: '/__data/assets/file/0015/29040/map-marker-dunedin.svg',
      scaledSize: new google.maps.Size(42, 42),
    }
  }
  if ($loretto.length) {
    icon = {
      // url: '/styles/mysource_files/map-marker-loretto.svg',
      url: '/__data/assets/file/0016/29041/map-marker-loretto.svg',
      scaledSize: new google.maps.Size(42, 42),
    }
  }
  if ($barony.length) {
    icon = {
      // url: '/styles/mysource_files/map-marker-barony.svg',
      url: '/__data/assets/file/0027/37638/map-marker-barony.svg',
      scaledSize: new google.maps.Size(42, 42),
    }
  }
  if ($lowther.length) {
    icon = {
      // url: '/styles/mysource_files/map-marker-barony.svg',
      url: '/__data/assets/file/0030/46893/map-marker-lowther.svg',
      scaledSize: new google.maps.Size(42, 42),
    }
  }
  if ($wheatley.length) {
    icon = {
      // url: '/styles/mysource_files/map-marker-wheatley.svg',
      url: '/__data/assets/file/0023/50477/map-marker-wheatley.svg',
      scaledSize: new google.maps.Size(42, 42),
    }
  }
  var office = {lat: lat, lng: lng};
  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: 18,
    center: office,
    disableDefaultUI: true,
    zoomControl: true
  });
  var marker = new google.maps.Marker({
    position: office,
    map: map,
    optimized: false,
    icon: icon
  });
}

$(function(){
  var $officeForm = $('#search-offices');
  if($officeForm.length){
    $officeForm.on('submit', function(event){
      event.preventDefault();
      $('#postcode-error').remove();
      // Get postcode, lowercase it and strip whitespace
      var postcode = $('.officesearchresultinput').val().toUpperCase().replace(/\s+/g, '');
      if(postcode){
        $.getJSON('https://api.postcodes.io/postcodes/' + postcode)
        .done(function(data) {
          var postcode = data.result.postcode,
              lat = data.result.latitude,
              lng = data.result.longitude;
          if(postcode && lat && lng){
            $('#search-offices [name="postcode"]').val(postcode);
            $('#search-offices [name="latlng"]').val(lat + ',' + lng);
            $officeForm.unbind().submit();
          }
        })
        .fail(function(data, textStatus, error){
          var error;
          if(data.responseJSON && data.responseJSON.error === 'Postcode not found'){
            error = 'Postcode not recognised. Please check postcode and try again.';
          }
          else{
            error = 'There was an unexpected error. Please try again later and contact us if the problem persists.';
          }
          if(error){
            $('#search-offices').prepend('<label id="postcode-error" class="error" for="search-bar">' + error + '</label>');
          }
        });
      }
    });
  }
});
