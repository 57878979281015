var tabInfo = {}
tabInfo.title
tabInfo.intro
tabInfo.par1
tabInfo.par2
tabInfo.icon1
tabInfo.icon2
tabInfo.image
tabInfo.ctalink

function getInformation (a) {
  // tabInfo.title = $('h3', a).text()
  tabInfo.par1 = $('.expanded_content', a).html()
  // tabInfo.icon1 = $('.expanded_mobileicon1', a).text()
  // tabInfo.icon2 = $('.expanded_mobileicon2', a).text()
  tabInfo.image = $('.expanded_image', a).attr('src')
  // tabInfo.ctalink = $('.expanded_ctalink', a).attr('href')
  // console.log(tabInfo)
}

function updateExpanded (b) {
  // b.next('.expanded').find('.expanded_title').text(tabInfo.title)
  b.next('.expanded').find('.expanded_content').html(tabInfo.par1)
  // b.next('.expanded').find('.expanded_mobileicon1').text(tabInfo.icon1)
  // b.next('.expanded').find('.expanded_mobileicon2').text(tabInfo.icon2)
  b.next('.expanded').find('.expanded_image').attr('src', tabInfo.image)
  // b.next('.expanded').find('.expanded_ctalink').attr('href', tabInfo.ctalink)
}

$(document).ready(function () {
  if ($('.yourplace-accordion-tiles').length) {

    $('.yourplacetiles > a').on('click', function (e) {
      e.preventDefault()
      var $_this = $(this)
      var $thisparent = $_this.parent()
      var $mainthisparent = $_this.parents('.container')
      var mainwidth = $(window).width()

      getInformation($thisparent.parent().next('.smallexpanded'))

      if (mainwidth > 991) { //desktop
        if ($('.expanded.big_open').length) { //one tab already expanded
          $('.expanded.big_open').toggleClass('big_open big_close').slideUp(400, function () {
            if ($thisparent.hasClass('active')) {
              $thisparent.toggleClass('active passive')
            } else {
              $('.yourplacetiles.active').toggleClass('active passive')
              updateExpanded($mainthisparent)
              $mainthisparent.next('.expanded').toggleClass('big_open big_close').slideDown(400, function () {
                $thisparent.toggleClass('active passive')
                // focus the open tab, comment out to remove
                var amount = $mainthisparent.next('.expanded').offset().top - 150
                $('html, body').animate({scrollTop: amount}, 400)
              })
            }
          })
        } else { //no tab expanded
          updateExpanded($mainthisparent)
          $mainthisparent.next('.expanded').toggleClass('big_open big_close').slideDown(400, function () {
            $thisparent.toggleClass('active passive')
            // focus the open tab, comment out to remove
            var amount = $mainthisparent.next('.expanded').offset().top - 150
            $('html, body').animate({scrollTop: amount}, 400)
          })
        }
      } else { //mobile
        if ($('.smallexpanded.small_open').length) { //one tab already expanded
          $('.smallexpanded.small_open').toggleClass('small_open small_close').slideUp(400, function () {
            if ($thisparent.hasClass('active')) {
              $thisparent.toggleClass('active passive')
            } else {
              $('.yourplacetiles.active').toggleClass('active passive')
              $thisparent.parent().next('.smallexpanded').toggleClass('small_open small_close').slideDown(400, function () {
                $thisparent.toggleClass('active passive')
              })
            }
          })
        } else { //no tab expanded
          $thisparent.parent().next('.smallexpanded').toggleClass('small_open small_close').slideDown(400, function () {
            $thisparent.toggleClass('active passive')
          })
        }
      }

    })
    $('.tile_expanded__close').on('click', function (e) {
      e.preventDefault()
      var $_this = $(this)
      var $mainthisparent = $_this.parents('.expanded')
      var mainwidth = $(window).width()

      if (mainwidth > 991) { //desktop
        $('.yourplacetiles.active').toggleClass('active passive')
        $mainthisparent.toggleClass('big_open big_close').slideUp(400)
      } else { //mobile
        $('.yourplacetiles.active').toggleClass('active passive')
        $_this.parents('.smallexpanded').toggleClass('small_open small_close').slideUp(400)
      }
    })

    //close the accordion on resize to prevent unwanted behaviour when stiching from desktop to mobile or rotating tablet
    $( window ).resize(function() {
      $('.yourplacetiles.active').toggleClass('active passive')
      $('.expanded.big_open').toggleClass('big_open big_close').slideUp(1)
      $('.smallexpanded.small_open').toggleClass('small_open small_close').slideUp(1)
    })

    //get parameter to expand the wanted service when arriving from the homepage
    var urlloc = window.location.href;
    if (urlloc.indexOf('sertype') != -1){
      $.urlParam = function(name){
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        return results[1] || 0;
      }
      var locationUrl = $.urlParam('sertype')
      $('.row.row-eq-height').find('[data-service=\'' + locationUrl + '\']').each( function() {
        var $tile = this;
        $('a.button', $tile).click()
      })
      window.history.pushState({url: "" + urlloc + ""}, "", "/services");
    }
  }
})