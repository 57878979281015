(function ($) {
  var $wheatleycarousel = $('.wheatleycarousel');

  $(function() {
    if ($wheatleycarousel.length) {
      $wheatleycarousel.slick({
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        infinite: true,
        fade: true,
        draggable: false,
        prevArrow: null,
        nextArrow: null,
        speed: 300
      });
    }
  });


})(jQuery);