$(function(){

  var $quoteCarousel = $('.quote-carousel__slide-container');

  if ($quoteCarousel.length) {

    var slideCount = $quoteCarousel.find('.quote-carousel__slide').length;

    // Initialise the Quote Carousel
    $quoteCarousel.slick({
      arrows: false,
      centerMode: true,
      dots: true,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: true,
            swipe: false
          }
        }
      ]
    });


    // Quote Carousel defaults:
    //  * Note the number of slides as this affects the behaviour
    if(slideCount === 1){
      $quoteCarousel.addClass('sq-slick-one-slide');
    }
    //  * Show the center slide and the slides next to it
    updateSlides('.slick-slide.slick-active');

    // Shows the center slide's neighbour slides and hides the rest
    // $center can be a dom element object or a selector string that can be used to find the object
    function updateSlides($center, noTransition){
      // Make sure $center is a dom element
      if(typeof $center === 'string'){
        $center = $quoteCarousel.find($center);
      }
      // The slides have a CSS transition. Because slick clones elements we need to prevent the transition in certain cases
      $quoteCarousel.find('.sq-slick-no-transition').removeClass('sq-slick-no-transition');
      if(noTransition){
        $quoteCarousel.find('.slick-slide').addClass('sq-slick-no-transition');
      }
      // Show the center's neighbour slides by adding this class
      $quoteCarousel.find('.sq-slick-center-neighbour').removeClass('sq-slick-center-neighbour');
      $center.prev().addClass('sq-slick-center-neighbour');
      $center.next().addClass('sq-slick-center-neighbour');
      // Hide the remaining slides
      $quoteCarousel.find('.sq-slick-hidden').removeClass('sq-slick-hidden');
      $center.prev().prevAll().addClass('sq-slick-hidden');
      $center.next().nextAll().addClass('sq-slick-hidden');
    }

    /*
        The following three click events identify the center slide and calls the updateSlides funtion with it.
        A class is added to the slider which prevents the click events from carrying on until slick's afterChange event has completed.
        The reason for the additional JS is because slick thinks it's showing one slide when we're actually showing three...
        ...it means that the slide with class '.slick-center.slick-active' isn't actually the one the user sees in the center of the page.
    */
    // Previous arrow
    $quoteCarousel.find('.slick-prev').click(function(){
      if(!$quoteCarousel.hasClass('sq-slick-scroll-active')){
        $quoteCarousel.addClass('sq-slick-scroll-active');
        if(parseInt($quoteCarousel.find('.slick-dots .slick-active button').text()) === slideCount){
          updateSlides('.slick-center.slick-cloned');
        }
        else {
          updateSlides('.slick-center.slick-active');
        }
      }
    });
    // Next arrow
    $quoteCarousel.find('.slick-next').click(function(){
      if(!$quoteCarousel.hasClass('sq-slick-scroll-active')){
        $quoteCarousel.addClass('sq-slick-scroll-active');
        if(parseInt($quoteCarousel.find('.slick-dots .slick-active button').text()) === 1){
          updateSlides('.slick-center.slick-cloned');
        }
        else {
          updateSlides('.slick-center.slick-active');
        }
      }
    });
    // Dots
    $quoteCarousel.find('.slick-dots li').click(function(){
      if(!$quoteCarousel.hasClass('sq-slick-scroll-active')){
        $quoteCarousel.addClass('sq-slick-scroll-active');
        var clickedSlide = parseInt($(this).children('button').text());
        updateSlides('.slick-slide[data-slick-index="' + (clickedSlide - 1) + '"]');
      }
    });

    // Slick BEFORE change
    $quoteCarousel.on('beforeChange', function(event, slick, currentSlide, nextSlide){
      $quoteCarousel.find('.sq-slick-no-transition').removeClass('sq-slick-no-transition');
    });

    // Slick AFTER change
    $quoteCarousel.on('afterChange', function(event, slick, currentSlide){
      updateSlides('.slick-center.slick-active', true);
      $quoteCarousel.removeClass('sq-slick-scroll-active');
    });

  }

});
