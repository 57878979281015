// Simple accordion for GHA Wheatley Group
// by Gianluca Canafoglia @ Squiz

(function ($) {

  $('.accordion').each(function () {
    var className = 'accordionWrap';
    if ($(this.parentNode).hasClass(className)) return;
    $(this).nextUntil(':not(.accordion)').addBack('.accordion').wrapAll('<div class="' + className + '">');
  });

  $('.accordionWrap').each(function () {
    if (!$(this).find('.accordion').hasClass('js-first-closed')) {
      $(this).find('.accordion').first().children('.accordion-body').addClass('active');
      //add aria attribute to active title
      // $(this).find('.accordion').first().children('.accordion-title').find('h2').attr("aria-expanded","true");
      //
      $(this).find('.accordion').first().find('.accordion-title > h2 > .accordion-icon').toggleClass('sq-close sq-open');
    }
  });


  $('.accordion > .accordion-body').each(function () {
    if (!$(this).hasClass('active')) {
      $(this).hide();
    }
    //add aria attribute to non active title
    // $(this).parent().next().find('h2').attr("aria-expanded","false");
    //
  });

  $('.accordionWrap > .accordion > .accordion-title > h2').click(function () {
    var thisWrap = $(this).closest('.accordionWrap');
    if ($(this).parent().next().hasClass('active')) {
      $(this).parent().next().removeClass('active').slideUp();
      $(this).children('.accordion-icon').toggleClass('sq-close sq-open');
      return;
    }
    $(".sq-open", thisWrap).toggleClass('sq-close sq-open');
    $(".accordion-body", thisWrap).removeClass('active').slideUp();
    $(this).parent().next().addClass('active').slideDown(400, function () {
      if (!$(this).children().hasClass('side')) {
        var amount2 = $(this).parent().offset().top - 10;
        $('html, body').animate({scrollTop: amount2}, 500);
      }
    });
    $(this).children('.accordion-icon').toggleClass('sq-close sq-open');
    return false;
  });

  if ($(".accordion-body table[id^='table']").length) {
    $(".accordion-body table[id^='table']").each(function(){
      $(this).wrap('<div class="repsonsive-table-wrapper"></div>');
    });
  }
})(jQuery);