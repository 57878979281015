(function ($) {

  //enable tooltip for non touch devices only
  if (!('ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch)) {
    $('[data-toggle="tooltip"]').tooltip()
  }

  $('.main-navigation > .nav > li').hoverIntent({
    over: addHover,
    out: removeHover,
    timeout: '80'
  })
  $('.main-navigation > .nav > li:last-child > a').hoverIntent({
    over: toRed,
    out: offRed,
    timeout: '80'
  })
})(jQuery);
// hoverIntent functions
function addHover () {
  $(this).addClass('main-nav-hover')
}
function removeHover () {
  $(this).removeClass('main-nav-hover')
}
function toRed () {
  $(this).css('background-color', '#9b0000')
}
function offRed () {
  $(this).css('background-color', 'transparent')
}

/* js fix for ie: number of columns */
(function ($) {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE '),
      trident = ua.indexOf('Trident/'),
      edge = ua.indexOf('Edge/');

  if (msie > 0 || trident > 0 || edge > 0 ) {
    $('.main-navigation__sub-nav-column-wrapper').each(function(){
      var num = $(this).find('.main-navigation__sub-nav-column').length;
      var newClass;
      $(this).find('.main-navigation__sub-nav-column').each(function(){
        switch (num) {
          case 6 :
            newClass = 'sixcolumn';
            break;
          case 7 :
            newClass = 'sevencolumn';
            break;
          default:
            newClass = '';
        }
        $(this).addClass(newClass);
      });
    })
  }
  
})(jQuery)