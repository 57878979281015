$(function () {
  //Initialize responsive menu
  var menu = new mlPushMenu(document.getElementById('mp-menu'), document.getElementById('trigger'));

  // Prevent default for second level links with sub-nav
  $('.mp-menu a').each(function () {
    if ($(this).siblings('div.mp-level').length > 0) {
      $(this).click(function () {
        return false;
      });
    }
  });

  var el = $('#login-trigger'),
      item = $('.login.main-navigation__first-level');

  el.on('click', function () {
    menu.resetMenu();
    setTimeout(function () {
      item.slideDown();
    }, 300);
  })
}());
