(function($) {

  function validateForm() {
    // $.validator.messages.required = '';
    $('.js-form-validation').validate({
      // highlight: function(element) {
      //   $(element).addClass('error');
      // }, unhighlight: function(element) {
      //   $(element).removeClass('error');
      // }
      errorPlacement:
          function(error, element){
            var id=element[0]['id'];
            $( element ).before( "<label for='"+id+"' class='error'>"+error.text()+"</label>" );
          }
    });
  }

  function showFormItems() {
    var mainSelect = $('#form__js-mainSelect select'),
        formItems = $('.form__questions');
    mainSelect.change(function () {
      if(mainSelect.find('option:selected').val() !== '') {
        formItems.fadeIn();
        $(this).addClass('is-selected');
      } else {
        formItems.fadeOut();
        $(this).removeClass('is-selected');
      }
    });
  }

  function showFormSubItems() {
    var radioSelect = $('#form__js-radioSelect input'),
        formSubitems = $('.form__subquestions');
    radioSelect.change(function () {
      if($(this).attr('value') === '0') {
        formSubitems.fadeIn();
      } else {
        formSubitems.fadeOut();
      }
    });
  }

  if($('.js-form-validation').length){
    validateForm();
  }
  if($('#form__js-mainSelect').length){
    showFormItems();
  }
  if($('#form__js-radioSelect').length){
    showFormSubItems();
  }

})(jQuery);
