// Simple accordion for GHA Wheatley Group
// by Maciej Bilski @ Squiz

(function ($) {
  var $carousel = $('.carousel');
 
  $(function() {
    if ($carousel.length) {
      $carousel.slick({        
        nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>'
      });
    }
  });
  
 
})(jQuery);