$(function () {
  var wrapper = $('.news_list .homepage'),
        arrowLeft = $('.news_n_blogs__arrows .icon-arrow-left'),
        arrowRight = $('.news_n_blogs__arrows .icon-arrow-right'),
        items = $('.news_list .homepage');

  var currentIndex = 0,
      itemAmt = items.length;

  function arrowsUpdate() {
    if (currentIndex === 0 ) {
      arrowLeft.addClass('disactive');
    } else {
      arrowLeft.removeClass('disactive');
    }

    if(currentIndex === itemAmt - 1) {
      arrowRight.addClass('disactive');
    } else {
      arrowRight.removeClass('disactive');
    }
  }

  function cycleItems() {
    var item = wrapper.eq(currentIndex);
    items.hide();
    item.css('display','inline-block');
  }

  arrowRight.click(function() {
    currentIndex += 1;
    if (currentIndex < itemAmt) {
      cycleItems();
    } else {
      currentIndex -= 1;
    }
    arrowsUpdate();
  });

  arrowLeft.click(function() {
    currentIndex -= 1;
    if (currentIndex >= 0) {
      cycleItems();
    } else {
      currentIndex += 1;
    }
    arrowsUpdate();
  });

$(document).ready(function () {
  if ($(window).width() < 991 && $('.news--template')) {
    cycleItems();
    arrowsUpdate();
  }
})

$(window).resize(function () {
  if ($(window).width() < 991) {
    cycleItems();
    arrowsUpdate();
  } else {
    items.show();
  }
})
}());
