(function( $ ) {

    $.fn.circliful = function(options) {

        var settings = $.extend({
            // These are the defaults.
            foregroundColor: "#556b2f",
            backgroundColor: "#eee",
            fillColor: false,
            width: 15,
            dimension: 200,
            size: 15,
            percent: 50,
            animationStep: 1.0
        }, options );
         return this.each(function() {
                var dimension = '';
                var text = '';
                var info = '';
                var width = '';
                var size = 0;
                var percent = 0;
                var endPercent = 100;
                var fgcolor = '';
                var bgcolor = '';
                var icon = '';
                var animationstep = 0.0;

                $(this).addClass('circliful');

                if($(this).data('dimension') != undefined) {
                    dimension = $(this).data('dimension');
                } else {
                    dimension = settings.dimension;
                }

                if($(this).data('width') != undefined) {
                    width = $(this).data('width');
                } else {
                    width = settings.width;
                }

                if($(this).data('fontsize') != undefined) {
                    size = $(this).data('fontsize');
                } else {
                    size = settings.size;
                }

                if($(this).data('percent') != undefined) {
                    percent = $(this).data('percent') / 100;
                    endPercent = $(this).data('percent');
                } else {
                    percent = settings.percent / 100;
                }

                if($(this).data('fgcolor') != undefined) {
                    fgcolor = $(this).data('fgcolor');
                } else {
                    fgcolor = settings.foregroundColor;
                }

                if($(this).data('bgcolor') != undefined) {
                    bgcolor = $(this).data('bgcolor');
                } else {
                    bgcolor = settings.backgroundColor;
                }

                if($(this).data('animation-step') != undefined) {
                    animationstep = parseFloat($(this).data('animation-step'));
                } else {
                    animationstep = settings.animationStep;
                }
                if($(this).data('text') != undefined) {
                    text = $(this).data('text');

                    if($(this).data('icon') != undefined) {
                        icon = '<i class="fa ' + $(this).data('icon') + '"></i>';
                    }

                     if($(this).data('type') != undefined) {
                        type = $(this).data('type');

                        if(type == 'half') {
                            $(this).append('<span class="circle-text-half">' +  icon  + text + '</span>');
                            $(this).find('.circle-text-half').css({'line-height': (dimension / 1.45) + 'px', 'font-size' : size + 'px' });
                        } else {
                            $(this).append('<span class="circle-text">' + icon + text + '</span>');
                            $(this).find('.circle-text').css({'line-height': dimension + 'px', 'font-size' : size + 'px' });
                        }
                    } else {
                        $(this).append('<span class="circle-text">' + icon + text + '</span>');
                        $(this).find('.circle-text').css({'line-height': dimension + 'px', 'font-size' : size + 'px' });
                    }
                } else if($(this).data('icon') != undefined) {

                }

                if($(this).data('info') != undefined) {
                    info = $(this).data('info');

                    if($(this).data('type') != undefined) {
                        type = $(this).data('type');

                        if(type == 'half') {
                            $(this).append('<span class="circle-info-half">' + info + '</span>');
                            $(this).find('.circle-info-half').css({'line-height': (dimension * 0.9) + 'px', });
                        } else {
                            $(this).append('<span class="circle-info">' + info + '</span>');
                            $(this).find('.circle-info').css({'line-height': (dimension * 1.25) + 'px', });
                        }
                    } else {
                        $(this).append('<span class="circle-info">' + info + '</span>');
                        $(this).find('.circle-info').css({'line-height': (dimension * 1.25) + 'px', });
                    }
                }

                $(this).width(dimension + 'px');

              var canvas = $('<canvas></canvas>').attr({ width: dimension, height: dimension }).appendTo($(this)).get(0);
              var context = canvas.getContext('2d');
              var x = canvas.width / 2;
              var y = canvas.height / 2;
              var degrees = percent * 360.0;
              var radians = degrees * (Math.PI / 180);
              var radius = canvas.width / 2.5;
              var startAngle = 2.3 * Math.PI;
              var endAngle = 0;
              var counterClockwise = false;
              var curPerc = animationstep === 0.0 ? endPercent : 0.0;
              var curStep = Math.max(animationstep, 0.0);
              var circ = Math.PI * 2;
              var quart = Math.PI / 2;
              var type = '';
              var fill = false;

              if($(this).data('type') != undefined) {
                    type = $(this).data('type');

                    if(type == 'half') {
                        var startAngle = 2.0 * Math.PI;
                        var endAngle = 3.13;
                        var circ = Math.PI * 1.0;
                        var quart = Math.PI / 0.996;
                    }
                }

                if($(this).data('fill') != undefined) {
                    fill = $(this).data('fill');
                } else {
                    fill = settings.fillColor;
                }
              //animate foreground circle
              function animate(current) {
                context.clearRect(0, 0, canvas.width, canvas.height);

                context.beginPath();
                context.arc(x, y, radius, endAngle, startAngle, false);
                context.lineWidth = width - 1;

                // line color
                context.strokeStyle = bgcolor;
                context.stroke();

                if(fill) {
                    context.fillStyle = fill;
                    context.fill();
                }

                context.beginPath();
                context.arc(x, y, radius, -(quart), ((circ) * current) - quart, false);
                context.lineWidth = width;
                // line color
                context.strokeStyle = fgcolor;
                context.stroke();

                if (curPerc < endPercent) {
                     curPerc += curStep;
                     requestAnimationFrame(function () {
                         animate(Math.min(curPerc, endPercent) / 100);
                     });
                }

             }

             animate(curPerc / 100);

        });

    };

}( jQuery ));

// ------------------------------
// Dog Foundation
// ------------------------------
// $(document).foundation();

// ------------------------------
// Show More Information
// ------------------------------
var ghaInformation = (function() {
    var dog;

    return {
        settings: {
            container: $('.row .item'),
            cta:  '.info',
            label : 'main-label',
            info: '.information-block',
            close:'.close',
            empty: 'empty'
        },

        init: function() {
            dog = this.settings;
            this.bindUIActions();
        },

        bindUIActions: function() {
            dog.container.each(function(){
			    var $this = $(this);
			    $this.find(dog.cta).click(function(){
			    	$this.find(dog.info).toggleClass('active');
			    	$this.prev().toggleClass('active');
			    });
                $this.find(dog.close).click(function(e){
                    e.preventDefault();
                    $this.find(dog.info).removeClass('active');
                    $this.prev().removeClass('active');
                });
			});

            $(dog.info).each(function(){
                var $this = $(this);
                if($this.length == "") {y
                    $this.addClass(dog.empty);
                }
            });
        }
    };
})();

// ------------------------------
// Weekly Or Monthly
// ------------------------------
var ghaPayments = (function() {
    var dog;

    return {
        settings: {
            container: $('.row .item'),
            radio:  '.radio label',
            input:  'input[type="radio"]',
            textfield:  'input[type="number"]',
            select: 'select',
            options: '.selector'
        },

        init: function() {
            dog = this.settings;
            this.bindUIActions();
        },

        bindUIActions: function() {
            dog.container.each(function(){
			    var $this = $(this);
			    $this.find(dog.radio).click(function(e){
			    	$this.find(dog.radio).removeClass("active");
			    	$(this).addClass('active');
			    	$(this).next(dog.input).click();
			    });
			});
        }
    };
})();

// ------------------------------
// Next Steps
// ------------------------------
var ghaSteps = (function() {
    var dog;

    return {
        settings: {
            button: $('.form-calculator a.button'),
            holder: $('.full.calculator'),
            active: 'active',
            result1 : $('.result-update #results-page1'),
            result2 : $('.result-update #results-page2'),
            result3 : $('.result-update #results-page3'),
            result4 : $('.result-update #results-page4')
        },

        init: function() {
            dog = this.settings;
            this.bindUIActions();
        },

        bindUIActions: function() {
            dog.button.click(function(e){
                e.preventDefault();
                dog.holder.removeClass(dog.active).attr('aria-hidden','true');
                var url = $(this).attr('href');
                $('html,body').animate({
                    scrollTop: 0
                },300);
                $(url).addClass(dog.active).attr('aria-hidden', 'false');
                dog.result1.empty().removeData().circliful();
                dog.result2.empty().removeData().circliful();
                dog.result3.empty().removeData().circliful();
                dog.result4.empty().removeData().circliful();
            });
        }
    };
})();



var ghaUpdate = (function() {
    var dog, self, totalIncome, totalCost, percentage;

    return {
        settings: {
            income: $('.form-calculator'),
            label : $('.form-calculator .radio label'),
            error : $('p.error'),
            active : 'active',
            result1 : $('.result-update #results-page1'),
            result2 : $('.result-update #results-page2'),
            result3 : $('.result-update #results-page3'),
            result4 : $('.result-update #results-page4'),
            radWeek : 'rw-',
            radMonth : 'rm-',
            radName : 'rn-',
            s1q1: 0,
            s1q2: 0,
            s1q3: 0,
            s1q4: 0,
            s2q1: 0,
            s2q2: 0,
            s3q1: 0,
            s3q2: 0,
            s3q3: 0,
            s3q4: 0,
            s3q5: 0,
            s3q6: 0,
            s3q7: 0,
            s3q8: 0,
            s3q9: 0,
            s3q10: 0,
            s3q11: 0,
            s3q12: 0,
            s3q13: 0,
            s3q14: 0,
            s4q1: 0,
            s4q2: 0,
            s4q3: 0,
            s4q4: 0,
            s4q5: 0,
            options: '.selector',
            hidden: 'hidden'
        },

        init: function() {
            self = this;
            dog = this.settings;
            this.bindUIActions();
        },

        income: function(id) {
            var returnValue = 0;
            var element = $('#'+ id).val();
            var ddElement = $('#' + id + 'dd option');
            var option = $('input[name="' + dog.radName + id + '"]:checked').attr('id');
            var week = $("#" + dog.radWeek + id);
            var month = $("#" + dog.radMonth + id);

            if (ddElement.length == "" || ddElement.filter(":selected").val() === "0") { //If Select is left alone or set to 0

                ddElement.parents('.item').children(dog.options).removeClass(dog.hidden);
                if(option == dog.radWeek + id) { // IF WEEKLY SELECTED
                    returnValue = (element * 1);
                } else if (option == dog.radMonth + id){ // IF MONTHLY SELECTED
                    returnValue = (element * 12) / 52;
                }

            } else {
                $('#'+ id).val('');
                ddElement.parents('.item').children(dog.options).addClass(dog.hidden);
                returnValue = (ddElement.filter(":selected").val() * 1);
            }
            return returnValue;
        },

        process: function() {

            // Step One
            dog.s1q1 = self.income("s1q1");
            dog.s1q2 = self.income("s1q2");
            dog.s1q3 = self.income("s1q3");
            dog.s1q4 = self.income("s1q4");

            // Step two
            dog.s2q1 = self.income("s2q1");
            dog.s2q2 = self.income("s2q2");
            dog.s2q3 = self.income("s2q3");

            // Step three
            dog.s3q1 = self.income("s3q1");
            dog.s3q2 = self.income("s3q2");
            dog.s3q3 = self.income("s3q3");
            dog.s3q4 = self.income("s3q4");
            dog.s3q5 = self.income("s3q5");
            dog.s3q6 = self.income("s3q6");
            dog.s3q7 = self.income("s3q7");
            dog.s3q8 = self.income("s3q8");
            dog.s3q9 = self.income("s3q9");

            // Step other
            dog.s3q10 = self.income("s3q10");
            dog.s3q11 = self.income("s3q11");
            dog.s3q12 = self.income("s3q12");
            dog.s3q13 = self.income("s3q13");
            dog.s3q14 = self.income("s3q14");

            // Step four
            dog.s4q1 = self.income("s4q1");
            dog.s4q2 = self.income("s4q2");
            dog.s4q3 = self.income("s4q3");
            dog.s4q4 = self.income("s4q4");
            dog.s4q5 = self.income("s4q5");

            // Total Income
            totalIncome = dog.s1q1 + dog.s1q2 + dog.s1q3 + dog.s1q4;

            // Total Cost
            totalCost = dog.s2q1 + dog.s2q2 + dog.s2q3 + dog.s3q1 + dog.s3q2 + dog.s3q3 + dog.s3q4 + dog.s3q5 + dog.s3q6 + dog.s3q7 + dog.s3q8 + dog.s3q9 + dog.s3q10 + dog.s3q11 + dog.s3q12 + dog.s3q13 + dog.s3q14 + dog.s4q1 + dog.s4q2 + dog.s4q3 + dog.s4q4 + dog.s4q5;

            var moneyLeftOver = (totalIncome - totalCost);

            percentage = (moneyLeftOver / totalIncome) * 100;

            if(moneyLeftOver > 0) {
                dog.error.hide();
                dog.result1.empty().removeData().attr('data-text', '&pound;' + moneyLeftOver.toFixed(2)).attr('data-percent',percentage).circliful({animation: 0, animationStep: 0});
                dog.result2.empty().removeData().attr('data-text', '&pound;' + moneyLeftOver.toFixed(2)).attr('data-percent',percentage).circliful({animation: 0, animationStep: 0});
                dog.result3.empty().removeData().attr('data-text', '&pound;' + moneyLeftOver.toFixed(2)).attr('data-percent',percentage).circliful({animation: 0, animationStep: 0});
                dog.result4.empty().removeData().attr('data-text', '&pound;' + moneyLeftOver.toFixed(2)).attr('data-percent',percentage).circliful({animation: 0, animationStep: 0});
            } else {
                dog.error.show();
                dog.result1.empty().removeData().attr('data-text', '&pound;' + moneyLeftOver.toFixed(2)).attr('data-percent',0).circliful({animation: 0, animationStep: 0});
                dog.result2.empty().removeData().attr('data-text', '&pound;' + moneyLeftOver.toFixed(2)).attr('data-percent',0).circliful({animation: 0, animationStep: 0});
                dog.result3.empty().removeData().attr('data-text', '&pound;' + moneyLeftOver.toFixed(2)).attr('data-percent',0).circliful({animation: 0, animationStep: 0});
                dog.result4.empty().removeData().attr('data-text', '&pound;' + moneyLeftOver.toFixed(2)).attr('data-percent',0).circliful({animation: 0, animationStep: 0});
            }


        },

        bindUIActions: function() {

            dog.income.change(function(){
                self.process();
            });

        }
    };
})();



// ------------------------------
// Document Ready
// ------------------------------
$(function() {
    if($('.budget-calculator').length){

    	ghaInformation.init();
    	ghaPayments.init();
        ghaSteps.init();
        ghaUpdate.init();

        $('.result-summary #results').circliful();
    	$('.result-update #results-page1').circliful();
        $('.result-update #results-page2').circliful();
        $('.result-update #results-page3').circliful();
        $('.result-update #results-page4').circliful();

    }
});
