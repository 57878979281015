// force update
(function ($) {
  $('.video-overlay').on('click', function(){
    var $videoWidget = $(this).parent('.video-widget');
    var videoUrl = $videoWidget.data('video-url');
    $videoWidget.append('<iframe class="embed-responsive-item" src="' + videoUrl + '" allowfullscreen></iframe>');

    $(this).fadeTo(250, 0, function(){
      $(this).addClass('visuallyhidden');
    }); // duration, opacity, callback
  });
})(jQuery);
