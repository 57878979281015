(function ($) {

  // Initialise Isotope.
  $(window).on('load', function () {
  var $container = $('.grid--news').isotope({
    itemSelector: '.grid-item--news',
    getSortData: {
      date: function (itemElem) {
        var date = Date.parse($(itemElem).find('.date').text())
        return date
      }
    },
    transitionDuration: 0,
    sortBy: 'date',
    sortAscending: true,
    masonry: {
      columnWidth: '.grid-sizer--news'
    }
  })

  // Initialise Isotope.
    var $container = $('.grid').isotope({
      itemSelector: '.grid-item',
      getSortData: {
        date: function (itemElem) {
          var date = Date.parse($(itemElem).find('.date').text())
          return date
        }
      },
      transitionDuration: 0,
      sortBy: 'date',
      sortAscending: false,
      masonry: {
        columnWidth: '.grid-sizer'
      }
    })

    $container.after('<div id="load-more"><div class="load-more"><div class="load-more-btn"></div></div></div>')

    var initShow = 6 //number of items loaded on init & onclick load more button
    var counter = initShow //counter for load more button
    var iso = $container.data('isotope') // get Isotope instance
    var filterValue = '*' //value of the filter

    $('select[name="socialfilter"]').change(function () {
      filterValue = $(this).find(':selected').attr('data-filter')
      $container.isotope({filter: filterValue})
      counter = initShow
      loadMore(counter, filterValue)

      //filter part: hide classes not filtered by isotope//
      if (filterValue == '.facebook') {
        $container.find('.twitter').addClass('hidden')
      }
      if (filterValue == '.twitter') {
        $container.find('.facebook').addClass('hidden')
      }
      //filter part end//

    })
    if ($('.socialbar').length) {
      loadMore(initShow, filterValue, true) //execute function onload of social wall page
    }

    function loadMore (toShow, filter, inititial) {
      $container.find(filter + '.hidden').removeClass('hidden')
      var hiddenElems = iso.filteredItems.slice(toShow, iso.filteredItems.length).map(function (item) {
        return item.element
      })
      $(hiddenElems).addClass('hidden')

      if (!inititial) {
        $container.css('opacity', 0)
      }
      $container.isotope('layout')

      if (!inititial) {
        $container.animate({opacity: 1}, 500)
      }
      // when no more to load, hide show more button
      if (hiddenElems.length == 0) {
        jQuery('#load-more').hide()
      } else {
        jQuery('#load-more').show()
      }


    }

    //when load more button clicked
    $('#load-more').click(function () {
      counter = counter + initShow
      loadMore(counter, filterValue)
    })
  })

})(jQuery);
