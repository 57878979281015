(function ($) {

  //disable options on dropdown maxPrice and minPrice based on Salary. maxPrice and minPrice are reset
  function outOut (o) {
    if ($('#propertysalary option:selected').val() == o) {
      $('#propertymin option').prop('disabled', false)
      $('#propertymax option').prop('disabled', false)
      $('#propertymin option').each(function () {
        if (parseInt($(this).val().split('=')[1]) > parseInt(o)) {
          $(this).prop('disabled', true)
        }
      })
      $('#propertymax option').each(function () {
        if (parseInt($(this).val().split('=')[1]) > parseInt(o)) {
          $(this).prop('disabled', true)
        }
      })
      $('#propertymin').val('minPrice=0')
      $('#propertymax').val('maxPrice=0')
    }
  }

  $('#propertysalary').change(function () {
    outOut($('#propertysalary option:selected').val())
  })

  //disable Salary dropdown if Commercial properties are selected
  $('#propertytype').change(function () {
    if ($('#propertytype option:selected').val().match(/^commercial/)) {
      $('#propertysalary option').each(function () {
        $(this).prop('disabled', true)
      })
      $('#propertysize').val('maxBedrooms=0&minBedrooms=10')
      $('#propertysize option').each(function () {
        $(this).prop('disabled', true)
      })
      $('#propertymin option').prop('disabled', false)
      $('#propertymax option').prop('disabled', false)
      $('#propertysalary').val('none')
    } else {
      $('#propertysalary option').each(function () {
        $(this).prop('disabled', false)
        outOut($('#propertysalary option:selected').val())
        $('#propertysalary option[value="none"]').prop('disabled', true)
        $('#propertysalary').val('400')
        $('#propertysize option').each(function () {
          $(this).prop('disabled', false)
        })
        $('#propertysize').val('maxBedrooms=0&minBedrooms=10')
      })
    }
  })

  $('#lowthersearch').on('click', function (e) {
    e.preventDefault()
    if ($('.lowther-searchfilter').length) {
      var type = $('#propertytype option:selected').val()
      var location = $('#propertylocation option:selected').val()
      var size = $('#propertysize option:selected').val()
      var minPrice = $('#propertymin option:selected').val()
      var maxPrice = $('#propertymax option:selected').val()
      var query


      if (type === 'residential') {
        type = ''
        query = 'http://www.rightmove.co.uk/property-to-rent/find.html?locationIdentifier=BRANCH%5E92879&' + size +
          '&' + maxPrice + '&' + minPrice + '&' + type + '&includeLetAgreed=true'
      } else if (type.match(/^commercial/)) {
        switch (type) {
          case 'commercialOffice':
            type = '&propertyTypes=office%2Cserviced-office%2Cbusiness-park'
            break
          case 'commercialRetail':
            type = '&propertyTypes=retail_property_high_street_%2Cretail_property_out_of_town_%2Cconvenience-store%2Cgarage%2Chairdresser-barber-shop%2Cpost-office%2Cshop%2Cworkshop'
            break
          case 'commercialLeisure':
            type = '&propertyTypes=hospitality%2Cleisure-facility%2Cbar-nightclub%2Ccafe%2Cguest-house%2Chotel%2Cpub%2Crestaurant%2Ctakeaway'
            break
          case 'commercialIndustrial':
            type = '&propertyTypes=distribution-warehouse%2Cfactory%2Cheavy-industrial%2Cindustrial-park%2Clight-industrial%2Cshowroom%2Cstorage%2Ctrade-counter%2Cwarehouse'
            break
          case 'commercialLand':
            type = '&propertyTypes=land%2Ccommercial-development%2Cindustrial-development%2Cresidential-development%2Cfarm'
            break
          case 'commercialOther':
            type = '&propertyTypes=land%2Ccommercial-development%2Cindustrial-development%2Cresidential-development%2Cfarm%2Cchildcare-facility%2Chealthcare-facility%2Cmixed-use%2Cpetrol_station%2Cplace_of_worship%2Ccommercial-property%2Cother'
            break
          default:
            type = ''
        }
        query = 'http://www.rightmove.co.uk/commercial-property-to-let/find.html?searchType=RENT&locationIdentifier=BRANCH%5E92879' + type + '&' + maxPrice + '&' + minPrice + '&includeLetAgreed=false&areaSizeUnit=sqft&priceType=pcm'
      } else {
        query = 'http://www.rightmove.co.uk/property-to-rent/find.html?locationIdentifier=BRANCH%5E92879&' + size +
          '&' + maxPrice + '&' + minPrice + '&' + type + '&includeLetAgreed=true'
      }
      window.open(query, '_blank')
    }
  })

})(jQuery)
