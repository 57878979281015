$(function () {
  var $searchTrigger = $('.search-trigger');
  var $searchOverlay = $('.search-overlay');

  $searchTrigger.on('click', function (e) {
    e.preventDefault();
    $searchOverlay.fadeIn("slow", function (e) {
      $('#query').focus();
      $('html').on('click', removeSearch);
    })
  });

  function removeSearch(e) {
    if (!$(e.target).is('.js-search-overlay-content-area , .js-search-overlay-content-area *')) {
      $searchOverlay.fadeOut("slow");
      $('html').off('click', removeSearch);
    }
  }

  $(document).keydown(function (event) {
    if (event.keyCode === 27) {
      $searchOverlay.fadeOut("slow");
    }
  });

});
