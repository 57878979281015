$( document ).ready(function() {
  var $locationimages = $('.location-images')
  var $floorplan = $('.floor-plan')

  $(function () {
    if ($('.lowther-tab').length) {
      $locationimages.slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>'
      })
      $floorplan.slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>'
      })
    }
  })

  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    if ($('#images').hasClass('active')) {
      $locationimages.slick('setPosition')
    }
    if ($('#floorplan').hasClass('active')) {
      $floorplan.slick('setPosition')
    }
    if ($('#map').hasClass('active')) {
      initMapLocation()
    }
  })

  $('.homevideo-overlay').on('click', function () {
    var $videoWidget = $(this).parent('.homevideo-widget')
    var videoUrl = $videoWidget.data('video-url')
    $videoWidget.append('<div class="video-popup"><iframe class="embed-responsive-item" src="' + videoUrl + '" allowfullscreen></iframe><div class="video-popup__close" hrev="#"></div></div>')
    $(this).fadeTo(250, 0, function () {
      $(this).addClass('visuallyhidden')
    }) // duration, opacity, callback
  })

  $(document).on('click', '.video-popup__close', function () {
    $('.video-popup').remove(function () {
    })
  })

  function initMapLocation () {
    var lat = $('.nearest_title').data('coord-lat')
    var lng = $('.nearest_title').data('coord-lon')
    var $lowther = $('.lowther')
    var icon
    if ($lowther.length) {
      icon = {
        // url: '/styles/mysource_files/map-marker-barony.svg',
        url: '/__data/assets/file/0030/46893/map-marker-lowther.svg',
        scaledSize: new google.maps.Size(42, 42),
      }
    }
    var office = {lat: lat, lng: lng}
    var map = new google.maps.Map(document.getElementById('gmap'), {
      zoom: 18,
      center: office,
      disableDefaultUI: true,
      zoomControl: true
    })
    var marker = new google.maps.Marker({
      position: office,
      map: map,
      optimized: false,
      icon: icon
    })
  }
});

