$(function () {
  cookies.init();
});

var cookies = {
  setState: function () {
      $('#cookie').click(function (e) {
          e.preventDefault();
          Cookies.set("announcement_info", "off", {path: '/', expires: 365});
          $('.cookie').remove();
      });
      // $('#cookiedecline').click(function (e) {
      //     e.preventDefault();
      //     // $.cookie("announcement_info", "off", { path: '/', expires : 365 });
      //     Cookies.remove("announcement_info", "off", {path: '/', expires: 365});
      // });
  },
  checkState: function () {
      if (Cookies.get("announcement_info") == undefined) {
          $('.cookie').removeClass('hidden');
      }
      else {
          $('.cookie').addClass('hidden');
      }
  },

  wheatleySetState: function () {
      $('.cookie-close').click(function (e) {
          e.preventDefault();
          Cookies.set("announcement_wheatley", {banner: 'false', optIn: 'true'}, {path: '/', expires: 365});
          $('.cookie').addClass('hidden');
          if ($('#wheatleySwitch').length > 0) {
              $('#wheatleySwitch span .onoffswitch .onoffswitch-checkbox').prop('checked', true);
          }
          cookies.wheatleyCheckState();
      });
  },
  wheatleyCheckState: function () {
      var obj = JSON.parse(Cookies.get('announcement_wheatley'));
      var actualBanner = obj.banner;
      if (obj.optIn == 'false') {
          $("#sharethis-script").attr('src', '');
          $("#googleTag").empty();
          Cookies.set("announcement_wheatley", {banner: 'true', optIn: 'false'}, {path: '/', expires: 365});
          if ($('#wheatleySwitch').length > 0) {
              $('#wheatleySwitch span .onoffswitch .onoffswitch-checkbox').prop('checked', false);
          }
      }
      else {
          if ($.trim($("#googleTag").html()) == '') {
              $("#sharethis-script").attr('src','//platform-api.sharethis.com/js/sharethis.js#property=58a2c2f06cfb4200128ad4e4&product=inline-share-buttons');
              $("#googleTag").append("(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){\n" +
                "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n" +
                "m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n" +
                "})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');\n" +
                "ga('create', 'UA-89297449-1', 'auto');\n" +
                "ga('send', 'pageview');" );
          }
          Cookies.set("announcement_wheatley", {banner: actualBanner, optIn: 'true'}, {path: '/', expires: 365});
          if ($('#wheatleySwitch').length > 0) {
              $('#wheatleySwitch span .onoffswitch .onoffswitch-checkbox').prop('checked', true);
          }
      }
      if (obj.banner == 'true') {
          $('.cookie').removeClass('hidden');
      } else {
          $('.cookie').addClass('hidden');
      }
  },
  wheatleySwitchState: function () {
      $("#wheatleySwitch span .onoffswitch .onoffswitch-checkbox").change(function (e) {
          e.preventDefault();
          if (this.checked) {
              Cookies.set("announcement_wheatley", {banner: 'false', optIn: 'true'}, {path: '/', expires: 365});
          } else {
              Cookies.set("announcement_wheatley", {banner: 'true', optIn: 'false'}, {path: '/', expires: 365});
          }
          cookies.wheatleyCheckState();
      });
  },
  init: function () {
      if ($('.cookie').length > 0) {
          cookies.setState();
          cookies.checkState();
      }

      if ($('body.wheatleyeatley'.length > 0)) {
          if (Cookies.get("announcement_wheatley") == undefined) {
              Cookies.set("announcement_wheatley", {banner: 'true', optIn: 'true'}, {path: '/', expires: 365});
          }
          cookies.wheatleySetState();
          cookies.wheatleyCheckState();
          cookies.wheatleySwitchState();
      }
  }
};